<script>
import moment from 'moment';

export default {
  name: 'logs-table',
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: 'Table columns',
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Table data',
    },
    limitExamples: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
      description: 'Whether table is striped or hover type',
    },
    theadClasses: {
      type: String,
      default: '',
      description: '<thead> css classes',
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: '<tbody> css classes',
    },
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      pages: [],
    };
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    displayedData() {
      return this.paginate(this.data);
    },
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== 'undefined';
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    setPages() {
      if (this.pages) {
        this.pages = [];
      }

      const numberOfPages = Math.ceil(this.data.length / this.perPage);

      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      const page = this.page;
      const perPage = this.perPage;

      const from = (page * perPage) - perPage;
      const to = (page * perPage);

      return data.slice(from, to);
    },

    formatDate(value) {
      if (moment(value).isValid()) {
        return moment(value).format('DD/MM/YYYY HH:mm');
      } else {
        return value;
      }
    },
  },
  watch: {
    data() {
      this.setPages();
    },
  },
};
</script>

<template>
  <div class="limits">
    <table class="table tablesorter limits-table" :class="tableClass">
      <thead :class="theadClasses">
      <tr>
        <slot name="columns">
          <th v-for="column in columns" :key="column">{{column}}</th>
        </slot>
      </tr>
      </thead>
      <tbody :class="tbodyClasses">
        <tr v-for="(item, index) in displayedData" :key="index">
          <td>{{ item.user }}</td>
          <td>{{ item.platform }}</td>
          <td>{{ item.class_method_name }}</td>
          <td>{{  formatDate(item.date) }}</td>
          <td>{{ item.content }}</td>
        </tr>
      </tbody>
    </table>

    <nav class="table-pagination">
      <ul class="pagination">
        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page != 1"
            @click="page--"
          >
            <i class="fas fa-angle-left"></i>
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
            :key="index"
            @click="page = pageNumber"
            :class="{ 'is-active': page === pageNumber }"
          >
            {{ pageNumber }}
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page < pages.length"
            @click="page++"
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>
